import packJson from '../../package.json';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const generic = {
  production: {
    web_domain: 'kinodaran.com',
    url_protocol: 'https',
    ws_protocol: 'wss',
    api_domain: 'api.kinodaran.com:9090',
    guestKey: 'C3C36F2E-0FD8-4DEE-A277-118852E4087B',
    stripeKey:
      'pk_live_51IWiRSJfXmEIu9BgCIHGqRhTDprg519mS73noDCeBLVypPqIOfDMxKeDZ3kggO4PaMCZ6ZDrSBxPg1wxDLVNsKM900ij5debhu',
    sentry_env: 'production',
    googleClient: '281777179087-9amtselg2hlmsk8vib8q78d0l1dps40h.apps.googleusercontent.com',
    facebookId: '3311001092280466',
    analytics_api: 'https://analytics.kinodaran.com:9095/api/v1/',
    idram_account_id: '110001624',
    appStoreLink: 'https://apps.apple.com/us/app/id1523806081',
    reCaptchaV2: '6LfNXdMmAAAAAJ6vQqdZhO3rnWkxffFajvCP-uzY',
  },
  development: {
    web_domain: 'dev.kinodaran.com',
    url_protocol: 'https',
    ws_protocol: 'wss',
    api_domain: 'devapi.kinodaran.com:9090',
    guestKey: 'C3C36F2E-0FD8-4DEE-A277-118852E4087B',
    stripeKey:
      'pk_test_51IWiRSJfXmEIu9BgrPMdpTR9JIG0DlElErTrz9sL4dDJ9DvIW8OLk5Rwvok1HqEEYm0ZA0vpR1CZIzfd7ULGIs23005sqOmzq2',
    sentry_env: 'development',
    googleClient: '174011846577-qp1lpj5boaacqe1qgj65lo92sd4bcg43.apps.googleusercontent.com',
    facebookId: '264472258363825',
    analytics_api: 'https://devapi.kinodaran.com/api/v1/',
    idram_account_id: '110001624',
    appStoreLink: 'https://apps.apple.com/us/app/itunes-connect/id376771144',
    reCaptchaV2: '6LfbfaomAAAAAAYsvmWiuHFOnMjbfAdbDP2Jcrpw',
  },
};

const getENV = () => {
  let env = process.env.REACT_APP_API_URL_ENV || process.env.NODE_ENV;
  env = env === 'test' ? 'development' : env;
  return env;
};

export const env = getENV() || 'development';
export const isProduction = env === 'production';

const main = generic[env];

export const isDevelopment = env === 'development';

export const HOST = {
  API: {
    URL: `${main.url_protocol}://${main.api_domain}`,
    WS: `${main.ws_protocol}://${main.api_domain}`,
    AUTH_HEADER: 'Authentication',
    AUTH_PREFIX: 'Bearer',
    GUEST_KEY: main.guestKey,
    STRIPE_KEY: main.stripeKey,
    GOOGLE_KEY: main.googleClient,
    FACEBOOK_KEY: main.facebookId,
    APPLE_KEY: 'com.techcraeft.kinodaran.service.id',
    DOMAIN: main.web_domain,
    ANALYTICS_API: main.analytics_api,
  },
};

export const LINKS = {
  AMERIA: 'https://join.myameria.am/?ref=CC4C9CE8-E6F5-4C04-B41A-6F018A503D4B',
};

export const ACCOUNTS = {
  IDRAM_ACCOUNT_ID: main.idram_account_id,
};

export const SECRETS = {
  RECAPTCHA_V2: main.reCaptchaV2,
};

export const APPS = {
  APPSTORE: main.appStoreLink,
  PLAY_MARKET: 'https://play.google.com/store/apps/details?id=com.techcraeft.kinodaran',
};

export const PAYMENTS = {
  RUB_PRICE: ['BG', 'RU', 'BY'],
  AMD_PRICE: ['AM'],
};

export const initSentry = () => {
  if (!main.sentry_env) return;
  const denyUrls = [/extensions\//i, /^chrome:\/\//i];
  const env = getENV();
  const releaseName = packJson.version + (env === 'production' ? '' : `-${env}`);
  const options = {
    dsn: 'https://9cb9d3a8c5394584bf6ad559bee8a3fe@o568217.ingest.sentry.io/5713070',
    environment: main.sentry_env,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: releaseName,
    denyUrls,
  };
  Sentry.init(options);
};
