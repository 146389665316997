import { initializeApp } from 'firebase/app';
import { getRemoteConfig, fetchAndActivate } from 'firebase/remote-config';
import { isProduction } from 'configs';
import {
  getAnalytics,
  logEvent as fireBaseLogEvent,
  setUserId,
  setUserProperties,
} from 'firebase/analytics';

import { getValue } from 'firebase/remote-config';

const firebaseProdConfig = {
  apiKey: 'AIzaSyDp2holXwVnRGDefo2dHbYOL3Pgo6XWW_g',
  authDomain: 'kinodaran.firebaseapp.com',
  databaseURL: 'https://kinodaran.firebaseio.com',
  projectId: 'kinodaran',
  storageBucket: 'kinodaran.appspot.com',
  messagingSenderId: '281777179087',
  appId: '1:281777179087:web:b496215a7e7a46dc19eaba',
  measurementId: 'G-ECDCLNGNHP',
};

const firebaseDevConfig = {
  apiKey: 'AIzaSyDqCwqeMCMB3seLDkU6llLoadcw46BlJw4',
  authDomain: 'kinodaran-dev.firebaseapp.com',
  projectId: 'kinodaran-dev',
  storageBucket: 'kinodaran-dev.firebasestorage.app',
  messagingSenderId: '174011846577',
  appId: '1:174011846577:web:e16886d29e904082ab7790',
  measurementId: 'G-GTZ0V53QJR',
};

let analyticsInstance = null;

// Initialize Firebase
const getAnalyticsConfig = () => {
  if (analyticsInstance !== null) return analyticsInstance;
  const app = initializeApp(isProduction ? firebaseProdConfig : firebaseDevConfig);
  analyticsInstance = getAnalytics(app);
  return analyticsInstance;
};

export const getRemoteConfigValue = async key => {
  try {
    const app = initializeApp(isProduction ? firebaseProdConfig : firebaseDevConfig);
    const remoteConfig = getRemoteConfig(app);
    remoteConfig.settings.minimumFetchIntervalMillis = 3600000 / 4;
    await fetchAndActivate(remoteConfig);
    return getValue(remoteConfig, key)?.asBoolean();
  } catch (error) {
    return false;
  }
};

export const setLogUserProperties = user => {
  try {
    if (window.location.href.includes('localhost')) return;
    const analytics = getAnalyticsConfig();
    setUserId(analytics, user.id);
    setUserProperties(analytics, {
      name: user.name,
      email: user.email,
      isGuest: user.email.includes('guest@gmail.com'),
      dateOfBirth: user.dob,
    });
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
  }
};

export const logEvent = (name, params) => {
  if (window.location.href.includes('localhost')) return;
  const analytics = getAnalyticsConfig();
  if (params) {
    fireBaseLogEvent(analytics, name, params);
  } else {
    fireBaseLogEvent(analytics, name);
  }
};

export const logEventPurchase = (id, amount, currency = 'USD') => {
  if (window.location.href.includes('localhost')) return;
  const analytics = getAnalyticsConfig();
  fireBaseLogEvent(analytics, 'purchase', {
    currency: currency,
    transaction_id: id,
    value: amount,
  });
};
